"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sortFields;

var _has = _interopRequireDefault(require("lodash/has"));

var _toposort = _interopRequireDefault(require("toposort"));

var _propertyExpr = require("property-expr");

var _Reference = _interopRequireDefault(require("../Reference"));

var _isSchema = _interopRequireDefault(require("./isSchema"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// @ts-expect-error
function sortFields(fields, excludes = []) {
  let edges = [];
  let nodes = [];

  function addNode(depPath, key) {
    var node = (0, _propertyExpr.split)(depPath)[0];
    if (!~nodes.indexOf(node)) nodes.push(node);
    if (!~excludes.indexOf(`${key}-${node}`)) edges.push([key, node]);
  }

  for (const key in fields) if ((0, _has.default)(fields, key)) {
    let value = fields[key];
    if (!~nodes.indexOf(key)) nodes.push(key);
    if (_Reference.default.isRef(value) && value.isSibling) addNode(value.path, key);else if ((0, _isSchema.default)(value) && 'deps' in value) value.deps.forEach(path => addNode(path, key));
  }

  return _toposort.default.array(nodes, edges).reverse();
}