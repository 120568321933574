"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _inheritsLoose;
function _inheritsLoose(subClass, superClass) {
    subClass.prototype = Object.create(superClass.prototype);
    subClass.prototype.constructor = subClass;
    subClass.__proto__ = superClass;
}
