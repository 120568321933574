"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _taggedTemplateLiteralLoose;
function _taggedTemplateLiteralLoose(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    strings.raw = raw;
    return strings;
}
