"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _interopRequireDefault;
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}
