"use strict";

exports.__esModule = true;
exports.default = ownerDocument;

/**
 * Returns the owner document of a given element.
 * 
 * @param node the element
 */
function ownerDocument(node) {
  return node && node.ownerDocument || document;
}

module.exports = exports["default"];