"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = monthsToYears;

var _index = _interopRequireDefault(require("../_lib/requiredArgs/index.js"));

var _index2 = require("../constants/index.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * @name monthsToYears
 * @category Conversion Helpers
 * @summary Convert number of months to years.
 *
 * @description
 * Convert a number of months to a full number of years.
 *
 * @param {number} months - number of months to be converted
 *
 * @returns {number} the number of months converted in years
 * @throws {TypeError} 1 argument required
 *
 * @example
 * // Convert 36 months to years:
 * const result = monthsToYears(36)
 * //=> 3
 *
 * // It uses floor rounding:
 * const result = monthsToYears(40)
 * //=> 3
 */
function monthsToYears(months) {
  (0, _index.default)(1, arguments);
  var years = months / _index2.monthsInYear;
  return Math.floor(years);
}

module.exports = exports.default;